<template>
  <a
    href="javascript:;"
    data-toggle="modal"
    data-target="#resend-modal-preview"
    class="flex items-center p-2 transition duration-300 ease-in-out btn text-white bg-theme-31 rounded-md gap-2"
    @click="handleClick"
  >
    <RefreshCwIcon class="w-4 h-4" />
    {{ isRTL ? 'اعد ارسال الكود' : 'Resend Code' }}
  </a>
</template>

<script>
import getIsVerified from '@/utils/getIsVerified'
import { mapActions, mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      loading: 'orders/view/loading',
      user: 'auth/currentUser',
      locale: 'layout/locale',
      OTPSended: 'verify/phone/OTPSended'
    }),
    phoneNumber() {
      return this.user.phoneNumber
    }
  },
  methods: {
    ...mapActions({
      sendOTP: 'verify/phone/sendOTP'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    async handleClick() {
      if (!getIsVerified()) {
        const locale = this.$i18n.locale
        const phoneNumber = this.phoneNumber
        await this.sendOTP({
          phoneNumber,
          locale
        })
      }
    }
  },
  inject: ['isVerified']
}
</script>
