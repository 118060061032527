<template>
  <div class="intro-y tab-content mt-5">
    <h1 class="py-5 text-lg font-semibold">
      {{ i18n('orders.rejectionDetails') }}
    </h1>
    <div
      class="bg-white intro-y flex flex-col sm:flex-row gap-5 justify-between p-5"
    >
      <div class="text-theme-6 lg:text-[17px]">
        {{ i18n('common.rejectionReason') }} : {{ reason }}
      </div>
      <!-- <button
        type="button"
        data-dismiss="modal"
        class="btn btn-primary flex gap-1"
        @click="doResend({ id: orderId })"
        :disabled="loading"
      >
        <RefreshCwIcon class="w-3.5 h-3.5" />
        {{ isRTL ? 'اعد ارسال الكود' : 'Resend Code' }}
      </button> -->
      <!-- <button
        type="button"
        data-dismiss="modal"
        class="btn btn-primary flex gap-1"
        @click="doResend({ id: orderId })"
        :disabled="loading"
      >
        <RefreshCwIcon class="w-3.5 h-3.5" />
        {{ isRTL ? 'اعد ارسال الكود' : 'Resend Code' }}
      </button> -->
      <!-- <a
        href="javascript:;"
        data-toggle="modal"
        data-target="#resend-modal-preview"
        class="flex items-center p-2 transition duration-300 ease-in-out btn text-white bg-theme-31 rounded-md"
        @click="() => (isVerified ? null : handleClick())"
      >
        {{ 'open' }}
      </a> -->
      <resend-btn />
      <!-- <resend-otp /> -->
    </div>
  </div>
  <resend-modal :orderId="orderId" class="z-10" />
</template>

<script>
import resendModal from '@/components/modals/resend/resend-modal.vue'
import { mapGetters } from 'vuex'
import resendBtn from './resend-btn.vue'

export default {
  components: { 'resend-modal': resendModal, 'resend-btn': resendBtn },
  props: {
    reason: {
      type: String,
      required: true
    },
    orderId: {
      required: true
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      loading: 'orders/view/loading'
    })
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
}
</script>

<style></style>
