<template>
  <div class="intro-y box w-full">
    <div ref="table" class="relative w-full">
      <div class="overflow-x-auto">
        <div class="intro-y box px-5 mt-5 flex items-center justify-start">
          <div class="text-sm font-normal flex items-center gap-4">
            <app-i18n
              code="orders.orderIds"
              class="font-bold whitespace-nowrap"
            />
            <div
              class="flex gap-2 flex-wrap"
              v-if="order.provider_name === 'bitaqaty'"
            >
              <span
                class="bg-slate-300 rounded-full px-3 py-1"
                v-for="ref in order.refNumbers"
                :key="ref"
                >{{ ref }}</span
              >
            </div>
            <span class="bg-slate-300 rounded-full px-3 py-1" v-else>{{
              zain_order_id
            }}</span>
          </div>
        </div>
        <table ref="tabulator" class="table table-report sm:mt-2">
          <thead>
            <tr>
              <th
                v-for="col in columns"
                :key="col.name"
                class="text-center whitespace-nowrap"
              >
                {{ i18n(col.label) }}
              </th>
            </tr>
          </thead>
          <tbody v-if="rows.length">
            <tr v-for="(row, index) in rows" :key="index" class="intro-x">
              <td class="text-center">
                <div class="font-medium whitespace-nowrap">
                  {{ row.id }}
                </div>
              </td>
              <td class="text-center">
                <div class="font-medium whitespace-nowrap">
                  {{ presenter(row, 'name') }}
                </div>
              </td>
              <td class="text-center">
                <div class="font-medium whitespace-nowrap">
                  {{ presenter(row, 'count') }}
                </div>
              </td>
              <td class="text-center">
                <div
                  class="font-medium whitespace-nowrap text-theme-10 dark:text-theme-30"
                >
                  {{ formatPrice(row.price) }}
                </div>
              </td>
              <td class="text-center">
                <div class="font-medium whitespace-nowrap">
                  <details v-if="row.serials?.length > 1">
                    <summary>SNs</summary>
                    <ul>
                      <li
                        v-for="sn in presenterSerials(row, 'SN_VALUE')"
                        :key="sn"
                      >
                        {{ sn }}
                      </li>
                    </ul>
                  </details>
                  <span v-else-if="row.serials?.length == 1">
                    {{ presenterSerials(row, 'SN_VALUE')[0] }}</span
                  >
                  <span v-else>ـــ</span>
                </div>
              </td>
              <td class="text-center">
                <div class="font-medium whitespace-nowrap">
                  <details v-if="row.serials?.length > 1">
                    <summary>PINs</summary>
                    <ul>
                      <li
                        v-for="pin in presenterSerials(row, 'PIN_VALUE')"
                        :key="pin"
                      >
                        {{ pin }}
                      </li>
                    </ul>
                  </details>
                  <span v-else-if="row.serials?.length == 1">
                    {{ presenterSerials(row, 'PIN_VALUE')[0] }}</span
                  >
                  <span v-else>ـــ</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <app-empty-page
          v-if="!rows.length"
          :label="i18n('empty.order')"
          label-class="text-lg font-medium"
          icon-size="4x"
          class="box w-full py-64"
        ></app-empty-page>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineComponent } from 'vue'
import { GenericModel } from '@/shared/model/generic-model'

export default defineComponent({
  name: 'products-list-table',
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      columns: [
        {
          name: 'productId',
          field: 'productId',
          label: 'orders.fields.productId',
          align: 'center'
        },
        {
          name: 'productName',
          field: 'productName',
          label: 'orders.fields.productName',
          align: 'center'
        },
        {
          name: 'productQuantity',
          field: 'productQuantity',
          label: 'orders.fields.productQuantity',
          align: 'center'
        },
        {
          name: 'productPrice',
          field: 'productPrice',
          label: 'orders.fields.productPrice',
          align: 'center'
        },
        {
          name: 'productSN',
          field: 'productSN',
          label: 'orders.fields.productSN',
          align: 'center'
        },
        {
          name: 'productPin',
          field: 'productPin',
          label: 'orders.fields.productPin',
          align: 'center'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode'
    }),
    rows() {
      return this.order?.provider_products_serials || []
    },
    currentPage() {
      return this.pagination.currentPage || 1
    }
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    },
    formatPrice(price) {
      const PRICE = (Math.round(price * 100) / 100).toFixed(2)
      return this.language == 'ar' ? `${PRICE}` : `${PRICE}`
    },
    presenterSerials(row, fieldName) {
      const val =
        row != null
          ? row?.serials
            ? row?.serials.map((serial) => serial[fieldName])
            : null
          : null

      return val || 'ـــ'
    },
    presenter(row, fieldName) {
      return GenericModel.presenter(row, fieldName)
    },
    presenterDate(row, fieldName) {
      return GenericModel.presenterDate(row, fieldName)
    },
    presenterTime(row, fieldName) {
      return GenericModel.presenterTime(row, fieldName)
    },
    presenterInteger(row, fieldName) {
      return GenericModel.presenterInteger(row, fieldName)
    },
    presenterDecimal(row, fieldName) {
      return GenericModel.presenterDecimal(row, fieldName)
    },
    presenterLocalization(row, fieldName) {
      return GenericModel.presenterLocalization(row, fieldName)
    },
    presenterArr(row, fieldName) {
      return row[fieldName] && row[fieldName].length > 0
        ? row[fieldName].join(', ')
        : 'ـــ'
    }
  }
})
</script>

<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}
.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}
.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>
