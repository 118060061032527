<template>
  <div class="intro-y box px-5 pt-5 mt-5" v-if="!record || loading">
    <div style="width: 100%; height: 30vh" class="flex justify-center">
      <loading-spinner
        type="controller"
        :duration="1000"
        :size="40"
        color="#4469A8"
        :loading="loading"
      />
    </div>
  </div>

  <div v-else>
    <div>
      <!-- Order Details -->
      <order-details :record="record" />

      <!-- Rejections Details -->
      <rejection-details
        v-if="record.rejection_reason && record.status === 'rejected'"
        :orderId="record.id"
        :reason="record.rejection_reason"
      />
      <!-- <rejection-details
        :orderId="record.id"
        :reason="record.rejection_reason || ''"
      /> -->

      <!-- Customer Details -->
      <customer-details :customer="record.customer" />
      <!-- Provider Details -->
      <div
        v-if="
          record && record.providers_name && record.providers_name.length > 0
        "
      >
        <div>
          <!-- provider  header  -->
          <h1 class="intro-y py-5 text-lg font-semibold mt-5">
            {{ i18n('orders.providerDetails') }}
          </h1>
          <!-- provider tabs & active tab  -->
          <div class="intro-y box px-5">
            <div
              class="nav navs flex-col sm:flex-row justify-center lg:justify-start"
              role="tablist"
            >
              <a
                v-for="provider in record.providers_name"
                :key="provider"
                :id="`${provider}`"
                data-toggle="tab"
                :data-target="`#${provider}`"
                href="javascript:;"
                class="py-4 sm:mr-8"
                :class="tab == provider ? 'active' : ''"
                role="tab"
                :aria-controls="provider"
                aria-selected="true"
                @click="tab = provider"
              >
                {{ provider }}
              </a>
            </div>
          </div>
        </div>
        <!-- selected Provider details -->
        <div
          class="intro-y tab-content mt-5"
          v-if="record && record.provider_orders.length > 0"
        >
          <div v-for="provider in record.providers_name" :key="provider">
            <div
              :id="provider"
              class="tab-pane active"
              role="tabpanel"
              :aria-labelledby="`${provider}`"
              v-if="tab == provider"
            >
              <product-tab
                :order="orderProviderDetails"
                :key="orderProviderDetails"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        class="intro-y tab-content mt-5"
        v-if="record && record.logs.length > 0"
      >
        <h1 class="py-5 text-lg font-semibold">
          {{ i18n('orders.logsDetails') }}
        </h1>
        <logs-details :logs="record.logs" />
      </div>
    </div>
  </div>
  <div id="reCAPTCHA" />
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import ProductsTab from '@/views/orders/products-tab.vue'
import customerDetails from './customer-details.vue'
import { GenericModel } from '@/shared/model/generic-model'
import rejectionDetailst from './rejection-details.vue'
import logsDetails from './logs-details.vue'
import orderDetails from './order-details.vue'

export default defineComponent({
  props: {
    id: {
      type: String,
      required: true
    }
  },
  components: {
    'product-tab': ProductsTab,
    'customer-details': customerDetails,
    'rejection-details': rejectionDetailst,
    'logs-details': logsDetails,
    'order-details': orderDetails
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      record: 'orders/view/record',
      findLoading: 'orders/view/loading',
      loading: 'orders/view/loading'
    }),
    activated() {
      return this.record && this.record.status === 'enabled'
    },
    tab() {
      return this.record?.providers_name[0] || ''
    },
    orderProviderDetails() {
      const tab = this.tab
      return this.record.provider_orders.find(
        order => order.provider_name === tab
      )
    }
  },
  created() {
    document.title = this.i18n('routes.app') + ' | ' + this.i18n('routes.users')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.users')
    }
  },
  methods: {
    ...mapActions({
      doFind: 'orders/view/doFind'
    }),
    presenterWithCurrency(row, fieldName) {
      const PRICE = GenericModel.presenterDecimal(row, fieldName)
      return row.currency === 'USD'
        ? this.language == 'ar'
          ? `${PRICE || 0} دولار`
          : `${PRICE || 0} USD`
        : this.language == 'ar'
        ? `${PRICE || 0} جنية`
        : `${PRICE || 0} EGP`
    },
    presenterDateTime(row, fieldName) {
      return GenericModel.presenterDateTime(row, fieldName)
    },
    i18n(key, args) {
      return this.$t(key, args)
    }
  },
  async beforeMount() {
    await this.doFind(this.id)
  }
})
</script>
