<template>
  <div id="resend-modal-preview" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog" ref="modalBody">
      <div class="modal-content">
        <div class="modal-body p-0">
          <confirm-content v-if="isVerified" :orderId="orderId" />
          <verify-content
            v-if="!isVerified && OTPSended"
            :orderId="orderId"
            :isOpen="isOpen"
          />
        </div>
      </div>
    </div>
  </div>
  <loading-spinner
    type="controller"
    :duration="1000"
    :size="40"
    color="#4469A8"
    :loading="isOpen && processing"
  />
</template>

<script setup>
import confirmContent from './confirmation/confirm-content.vue'
import verifyContent from './verification/verify-content.vue'
import { computed, defineProps, onUnmounted, ref, watch } from 'vue'
import { useIntersectionObserver } from '@vueuse/core'
import { useStore } from '@/store'
import getIsVerified from '@/utils/getIsVerified.js'

defineProps({
  orderId: {
    required: true
  }
})

const store = useStore()

const modalBody = ref(null)
const isOpen = ref(false)
const isVerified = ref(true)
const OTPSended = computed(() => store.getters['verify/phone/OTPSended'])
const processing = computed(() => store.getters['verify/phone/processing'])

const { stop } = useIntersectionObserver(modalBody, ([{ isIntersecting }]) => {
  isOpen.value = isIntersecting
})

watch(isOpen, () => {
  if (!isOpen.value) {
    store.dispatch('verify/phone/clearOTPSended')
    store.dispatch('verify/phone/clearProcessing')
  }
  isVerified.value = getIsVerified()
})

onUnmounted(() => {
  store.dispatch('verify/phone/clearOTPSended')

  stop()
})
</script>
