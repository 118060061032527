<template>
  <div class="bg-white p-5">
    <ul>
      <li class="py-2" v-for="log in logs" :key="log">{{ log }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    logs: {
      type: Array,
      required: true
    }
  }
}
</script>
