<template>
  <div class="intro-y tab-content mt-5">
    <h1 class="py-5 text-lg font-semibold">
      {{ i18n('orders.customerDetails') }}
    </h1>
    <div class="intro-y box w-full">
      <div ref="table" class="relative w-full">
        <div class="overflow-x-auto">
          <table ref="tabulator" class="table table-report sm:mt-2">
            <thead>
              <tr>
                <th v-for="col in columns" :key="col.name" class="text-center">
                  {{ i18n(col.label) }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="intro-x">
                <td class="text-center">
                  <div class="font-medium">
                    {{ customer.id }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium">
                    {{
                      customer.fullName ?? customer.first_name
                        ? customer.first_name + ' ' + (customer.last_name ?? '')
                        : customer.default_address.first_name +
                          ' ' +
                          customer.default_address.last_name
                    }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium">
                    {{ customer.email }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium">
                    {{ customer.default_address.phone ?? customer.phone }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium">
                    {{ presenterDateTime(customer, 'created_at') }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineComponent } from 'vue'
import { GenericModel } from '@/shared/model/generic-model'

export default defineComponent({
  name: 'products-list-table',
  props: {
    customer: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      columns: [
        {
          name: 'id',
          field: 'id',
          label: 'orders.fields.id',
          align: 'center'
        },
        {
          name: 'fullName',
          field: 'fullName',
          label: 'orders.fields.fullName',
          align: 'center'
        },
        {
          name: 'email',
          field: 'email',
          label: 'orders.fields.email',
          align: 'center'
        },
        {
          name: 'phone',
          field: 'phone',
          label: 'orders.fields.phone',
          align: 'center'
        },
        {
          name: 'created_at',
          field: 'created_at',
          label: 'orders.fields.createdAt',
          align: 'center'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode'
    })
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    },

    presenter(row, fieldName) {
      return GenericModel.presenter(row, fieldName)
    },
    presenterDateTime(row, fieldName) {
      return GenericModel.presenterDateTime(row, fieldName)
    }
  }
})
</script>

<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}
.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}
</style>
