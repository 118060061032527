<template >
  <div>
    <div class="flex flex-col items-center pt-8 mb-7">
      <h2 class="text-xl mb-2">
        {{ i18n('common.verifyModal.enterOTP') }}
      </h2>
      <div class="flex justify-center gap-1">
        <span>{{ i18n('common.verifyModal.codeSendTo') }}</span>
        <span dir="ltr">
          {{ phoneNumber }}
        </span>
      </div>
    </div>
    <form @submit.prevent="doSubmit" :disabled="!isValidInput">
      <div class="w-full flex justify-center mb-7" dir="ltr">
        <otp-input
          :inputsCount="OTPInputsCount"
          @input="handleInput"
          :is-open="isOpen"
        />
      </div>
      <div class="flex justify-center mb-6 text-sm">
        <p v-if="timer > 0">
          {{ i18n('common.verifyModal.sendAgainIn', [timer]) }}
        </p>
        <button
          class="text-primary-3 animate-infinite flex items-center gap-1.5"
          @click="handleClick"
          :disabled="timer > 0"
          v-else
        >
          <RefreshCwIcon class="w-3.5 h-3.5" />
          {{ i18n('common.verifyModal.sendAgain') }}
        </button>
      </div>
      <div class="px-5 pb-8 text-center">
        <button
          type="submit"
          data-dismiss="modal"
          class="btn text-white bg-theme-31 w-24"
          :disabled="!isValidInput"
        >
          {{ i18n(`common.confirm`) }}
        </button>
        <button
          type="button"
          data-dismiss="modal"
          class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 ml-1 mr-1"
        >
          {{ i18n(`common.cancel`) }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import otpInput from './otp-input.vue'

export default {
  data() {
    return {
      modal: document.getElementById('verify-modal-preview'),
      OTPInputsCount: 6,
      inputValue: '',
      isValidInput: false,
      timer: 60,
      intervalId: null
    }
  },
  props: {
    item: {
      type: String
    },
    isOpen: {
      required: true
    },
    orderId: {
      required: true
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/currentUser',
      locale: 'layout/locale'
    }),
    phoneNumber() {
      return this.user.phoneNumber
    },
    isRTL() {
      return this.$i18n.locale == 'ar'
    }
  },
  methods: {
    ...mapActions({
      sendOTP: 'verify/phone/sendOTP',
      link: 'verify/phone/link',
      clearOTPSended: 'verify/phone/clearOTPSended',
      clearProcessing: 'verify/phone/clearProcessing'
    }),
    doSubmit() {
      this.link({
        code: this.inputValue,
        orderId: this.orderId,
        locale: this.$i18n.locale
      })
    },
    i18n(key, args) {
      return this.$t(key, args)
    },
    handleInput(e) {
      this.inputValue = e
      this.isValidInput = e.toString().length === this.OTPInputsCount
    },
    async handleClick() {
      this.clearOTPSended()
      this.clearProcessing()
      const phoneNumber = this.phoneNumber
      const locale = this.$i18n.locale
      await this.sendOTP({ phoneNumber, locale })
    }
  },
  mounted() {
    this.timer = 60
    this.intervalId = setInterval(() => {
      if (this.timer < 1) {
        clearInterval(this.intervalId)
      } else {
        this.timer -= 1
      }
    }, 1000)
  },
  unmounted() {
    clearInterval(this.intervalId)
    this.timer = 60
    this.clearOTPSended()
  },
  components: {
    'otp-input': otpInput
  }
}
</script>
