<template>
  <div class="p-5 text-center">
    <slot name="icon"></slot>
    <div class="text-3xl mt-5">
      {{ i18n('common.deleteModal.areYouSure') }}
    </div>
    <div class="text-gray-600 mt-2 dark:text-gray-400">
      {{ i18n('common.confirmModal.confirm', [orderId]) }}
    </div>
  </div>
  <div class="px-5 pb-8 text-center flex items-center justify-center gap-2">
    <button
      type="button"
      data-dismiss="modal"
      class="btn bg-theme-24 text-white w-24"
      @click="doResend({ id: orderId })"
    >
      {{ i18n(`common.confirm`) }}
    </button>
    <button
      type="button"
      data-dismiss="modal"
      class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300"
    >
      {{ i18n(`common.cancel`) }}
    </button>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  props: {
    orderId: {
      required: true
    }
  },
  computed: {
    isRTL() {
      return this.$i18n.locale == 'ar'
    }
  },
  methods: {
    ...mapActions({
      doResend: 'orders/view/doResend',
      clearOTPASended: 'verify/phone/clearOTPSended'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    }
  },
  unmounted() {
    this.clearOTPASended()
  }
}
</script>
