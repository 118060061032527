<template>
  <VOtpInput
    ref="otpInput"
    :value="val"
    input-classes="otp-input"
    :num-inputs="inputsCount || 6"
    :separator="''"
    :should-auto-focus="true"
    input-type="letter-numeric"
    :placeholder="Array(inputsCount).fill('-')"
    @onChange="handleOnChange"
    @onComplete="handleOnComplete"
    v-if="isOpen"
  />
</template>

<script setup >
import { ref, defineEmits, defineProps, watchEffect } from 'vue'
import VOtpInput from 'vue3-otp-input'

const otpInput = ref(null)

const val = ref('')

const props = defineProps(['inputsCount', 'isOpen'])
const emit = defineEmits(['input', 'complete'])

const handleOnComplete = (e) => {
  emit('complete', e)
}

const handleOnChange = (e) => {
  emit('input', e)
}

watchEffect(() => {
  if (!props.isOpen) val.value = ''
}, [props.isOpen])
</script>
<style >
.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
}
/* Background colour of an input field with value */
.otp-input.is-complete {
  @apply bg-gray-100;
}
.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input::placeholder {
  font-size: 15px;
  text-align: center;
  font-weight: 600;
}
</style>
