<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-semibold" :class="isRTL ? 'ml-auto' : 'mr-auto'">
        {{ i18n('orders.details') }}
      </h2>
    </div>
    <div class="intro-y box mt-5">
      <div class="overflow-x-auto">
        <table ref="tabulator" class="table sm:mt-2">
          <thead>
            <tr>
              <th v-for="col in columns" :key="col.name" class="text-center">
                {{ i18n(col.label) }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="intro-x">
              <td class="text-center">
                <div class="font-medium">
                  {{ record.shopify_order_id || record.id }}
                </div>
              </td>
              <td class="text-center">
                <div class="font-medium">
                  {{ record.shopify_order_name }}
                </div>
              </td>
              <td class="text-center">
                <div class="font-medium">
                  {{ presenterWithCurrency(record, 'shopify_total_price') }}
                </div>
              </td>
              <td class="text-center">
                <div class="font-medium">
                  {{ presenterWithCurrency(record, 'provider_total_price') }}
                </div>
              </td>
              <td class="text-center">
                <div class="font-medium">
                  {{ record.ipAddress }}
                </div>
              </td>
              <td class="text-center">
                <div class="font-medium">
                  <div
                    class="flex flex-col justify-center items-center mt-2"
                    v-if="record && record.status"
                  >
                    <div
                      class="flex items-center justify-center gap-2"
                      :class="{
                        'text-theme-24':
                          record.status === 'rejected' ||
                          record.status === 'failed',
                        'text-green-600 dark:text-theme-30':
                          record.status === 'success',
                        'text-theme-11': record.status === 'resend_success',
                        'text-gray-600': record.status === 'processing'
                      }"
                    >
                      <CheckSquareIcon
                        class="w-[1.2rem] h-[1.2rem]"
                        v-if="record.status === 'success'"
                      />
                      <ArrowUpCircleIcon
                        class="w-[1.2rem] h-[1.2rem]"
                        v-else-if="record.status === 'resend_success'"
                      />
                      <XCircleIcon
                        class="w-[1.2rem] h-[1.2rem]"
                        v-else-if="
                          record.status === 'rejected' ||
                          record.status === 'failed'
                        "
                      />
                      <LoaderIcon
                        class="w-[1.2rem] h-[1.2rem]"
                        v-else-if="record.status === 'processing'"
                      />
                      {{ i18n(`common.${record.status}`) }}
                    </div>
                  </div>
                </div>
              </td>
              <!-- <td class="text-center">
                <div class="font-medium">
                  {{ presenterDateTime(record, 'shopify_order_closedAt') }}
                </div>
              </td> -->
              <td class="text-center">
                <div class="font-medium">
                  {{ presenterDateTime(record, 'createdAt') }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- <div
        class="flex lg:flex-row border-gray-200 dark:border-dark-5 pb-5 -mx-5"
      >
        <div
          class="flex flex-1 px-5 items-center justify-center lg:justify-start"
        >
          <div class="ml-5 mr-5 flex justify-between flex-wrap flex-1 gap-4">
            <div class="flex-1">
              <div
                class="font-medium text-center mt-3 text-base whitespace-nowrap"
                :class="isRTL ? 'lg:text-right' : 'lg:text-left'"
              >
                {{
                  i18n(
                    record.shopify_order_id
                      ? 'orders.fields.shopifyOrderId'
                      : 'orders.fields.orderId'
                  )
                }}
              </div>
              <div
                class="flex flex-col justify-center items-center lg:items-start mt-2"
              >
                {{ record.shopify_order_id || record.id }}
              </div>
            </div>
            <div class="flex-1">
              <div
                class="font-medium text-center mt-3 text-base whitespace-nowrap"
                :class="isRTL ? 'lg:text-right' : 'lg:text-left'"
              >
                {{ i18n('orders.fields.shopifyOrderNumber') }}
              </div>
              <div
                class="flex flex-col justify-center items-center lg:items-start mt-2"
              >
                {{ record.shopify_order_name }}
              </div>
            </div>
            <div class="flex-1">
              <div
                class="font-medium text-center mt-3 text-base whitespace-nowrap"
                :class="isRTL ? 'lg:text-right' : 'lg:text-left'"
              >
                {{ i18n('orders.fields.shopifyTotalPrice') }}
              </div>
              <div
                class="flex flex-col justify-center items-center lg:items-start mt-2"
              >
                {{ presenterWithCurrency(record, 'shopify_total_price') }}
              </div>
            </div>
            <div class="flex-1">
              <div
                class="font-medium text-center mt-3 text-base whitespace-nowrap"
                :class="isRTL ? 'lg:text-right' : 'lg:text-left'"
              >
                {{ i18n('orders.fields.providerTotalPrice') }}
              </div>
              <div
                class="flex flex-col justify-center items-center lg:items-start mt-2"
              >
                {{ presenterWithCurrency(record, 'provider_total_price') }}
              </div>
            </div>
            <div class="flex-1">
              <div
                class="font-medium text-center mt-3 text-base whitespace-nowrap"
                :class="isRTL ? 'lg:text-right' : 'lg:text-left'"
              >
                {{ i18n('orders.fields.ipAddress') }}
              </div>
              <div
                class="flex flex-col justify-center items-center lg:items-start mt-2"
              >
                {{ record.ipAddress }}
              </div>
            </div>

            <div class="flex-1">
              <div
                class="font-medium text-center mt-3 text-base whitespace-nowrap"
                :class="isRTL ? 'lg:text-right' : 'lg:text-left'"
              >
                {{ i18n('orders.fields.status') }}
              </div>
              <div
                class="flex flex-col justify-center items-center lg:items-start mt-2"
                v-if="record && record.status"
              >
                <div
                  class="flex items-center justify-center gap-2"
                  :class="{
                    'text-theme-24':
                      record.status === 'rejected' ||
                      record.status === 'failed',
                    'text-green-600 dark:text-theme-30':
                      record.status === 'success',
                    'text-theme-11': record.status === 'resend_success',
                    'text-gray-600': record.status === 'processing'
                  }"
                >
                  <CheckSquareIcon
                    class="w-[1.2rem] h-[1.2rem]"
                    v-if="record.status === 'success'"
                  />
                  <ArrowUpCircleIcon
                    class="w-[1.2rem] h-[1.2rem]"
                    v-else-if="record.status === 'resend_success'"
                  />
                  <XCircleIcon
                    class="w-[1.2rem] h-[1.2rem]"
                    v-else-if="
                      record.status === 'rejected' || record.status === 'failed'
                    "
                  />
                  <LoaderIcon
                    class="w-[1.2rem] h-[1.2rem]"
                    v-else-if="record.status === 'processing'"
                  />
                  {{ i18n(`common.${record.status}`) }}
                </div>
              </div>
            </div>
            <div class="flex-1" v-if="record.shopify_order_closedAt">
              <div
                class="font-medium text-center mt-3 text-base whitespace-nowrap"
                :class="isRTL ? 'lg:text-right' : 'lg:text-left'"
              >
                {{ i18n('orders.fields.shopifyOrderClosedAt') }}
              </div>
              <div
                class="flex flex-col justify-center items-center lg:items-start mt-2"
              >
                {{ presenterDateTime(record, 'shopify_order_closedAt') }}
              </div>
            </div>
            <div class="flex-1">
              <div
                class="font-medium text-center mt-3 text-base whitespace-nowrap"
                :class="isRTL ? 'lg:text-right' : 'lg:text-left'"
              >
                {{ i18n('orders.fields.requestedAt') }}
              </div>
              <div
                class="flex flex-col justify-center items-center lg:items-start mt-2"
              >
                {{ presenterDateTime(record, 'createdAt') }}
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { GenericModel } from '@/shared/model/generic-model'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      columns: [
        {
          name: 'shopify_order_id',
          field: 'shopify_order_id',
          label: 'orders.fields.orderId',
          align: 'center'
        },
        {
          name: 'shopify_order_name',
          field: 'shopify_order_name',
          label: 'orders.fields.shopifyOrderNumber',
          align: 'center'
        },
        {
          name: 'shopify_total_price',
          field: 'shopify_total_price',
          label: 'orders.fields.shopifyTotalPrice',
          align: 'center'
        },
        {
          name: 'provider_total_price',
          field: 'provider_total_price',
          label: 'orders.fields.providerTotalPrice',
          align: 'center'
        },
        {
          name: 'ipAddress',
          field: 'ipAddress',
          label: 'orders.fields.ipAddress',
          align: 'center'
        },
        {
          name: 'status',
          field: 'status',
          label: 'orders.fields.status',
          align: 'center'
        },
        // {
        //   name: 'order_closedAt',
        //   field: 'order_closedAt',
        //   label: 'orders.fields.shopifyOrderClosedAt',
        //   align: 'center'
        // },
        {
          name: 'closedAt',
          field: 'closedAt',
          label: 'orders.fields.requestedAt',
          align: 'center'
        }
      ]
    }
  },
  props: {
    record: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL'
    })
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    },
    presenterWithCurrency(row, fieldName) {
      const PRICE = GenericModel.presenterDecimal(row, fieldName)
      return row.currency === 'USD'
        ? this.language == 'ar'
          ? `${PRICE || 0} دولار`
          : `${PRICE || 0} USD`
        : this.language == 'ar'
        ? `${PRICE || 0} جنية`
        : `${PRICE || 0} EGP`
    },
    presenterDateTime(row, fieldName) {
      return GenericModel.presenterDateTime(row, fieldName)
    }
  }
}
</script>
